/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 6 version
*/

.footer-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 56px;
  background-color: var(--footer-colour-background);
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  border-width: 0px;
  border-top-width: 1px;
  border-style: groove;
  border-color: black;
  position: sticky; top: 0;
  z-index: 10;
}

.footer-text{
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 300;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}

.footer-text-right{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  margin-right: 10px;
}

.icon{
  max-width: 24px;
  max-height: 24px;
  padding: 2px;
  text-decoration: none;
}

.icon :visited{
  text-decoration: none;
}