/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 6 version
*/


:root {
  --navbar-colour-background: #FBDDCA;
}

body{
  margin: 0;
  padding: 0;
  background-color: #FBDDCA;
  background-image: -webkit-gradient(linear,
  left top, left bottom,
  from(#FFDCC5), 
  color-stop(#FFDCBE),
  color-stop(#FFDCBE),
  color-stop(#FFDDAE),
  color-stop(#FFDCC6),
  to(#ECDCFF)
  );
  background-image: -o-linear-gradient(top,
  #FFDCC5, 
  #FFDCBE,
  #FFDCBE,
  #FFDDAE,
  #FFDCC6,
  #ECDCFF
  );
  background-image: linear-gradient(180deg,
  #FFDCC5, 
  #FFDCBE,
  #FFDCBE,
  #FFDDAE,
  #FFDCC6,
  #ECDCFF
  );

  cursor: url("/public/icons8-cursor-40.png"), auto;
}

body a{
  cursor: url("/public/icons8-cursor-40.png"), auto;
}

.content-main{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100;
  z-index: 1;
  padding-left: 20px;
  padding-right: 20px;
}

/* .bg-prerona-29{
} */
/* 
.bg-prerona-16{
  background-color: #DF95BF ;
} */

.gradient_29_16{
  /* height: 48px; */
  width: 100%;
  background: #FBDDCA;
  background: -webkit-gradient(linear, left top, left bottom, from(#FBDDCA), to(#BCA79B));
  background: -o-linear-gradient(top, #FBDDCA 0%, #BCA79B 100%);
  background: linear-gradient(180deg, #FBDDCA 0%, #BCA79B 100%);
}

.background-content{
  display: block;
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  /* border: #000;
  border-style: solid; */
}

.content-margin{
  padding-bottom: 20px;
  padding-top: 20px;
  height: 100vh;
}

@media (max-width: 768px) {
  /* put your css styles in here */
  .hide {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}

.flex-start{
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: left;
}

.flex-end{
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-align: right;
}

.flex-center{
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.full-width{
  width: 100%;
}

.item{
  padding: 20px;
}