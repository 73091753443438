/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 6 version
*/

.image{
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  /* padding: 20px; */
  -webkit-transition: height 2s;
  -o-transition: height 2s;
  transition: height 2s;
}

.image[fadeIn="1"] {
  -webkit-animation: fadeIn 0.5s ease;
          animation: fadeIn 0.5s ease;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}